<template>
  <div>
    <div class="card">
      <Toolbar class="mb-4" v-if="isMaster">
        <template #start>
          <Button
            label="Eintrag löschen"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="confirmDeleteSelected"
            :disabled="!selectedIOs || !selectedIOs.length"
          />
        </template>

        <template #end>
          <Button
            label="Export"
            icon="pi pi-upload"
            class="p-button-help"
            @click="exportCSV($event)"
          />
        </template>
      </Toolbar>

      <!-- LOG TABLE -->
      <DataTable
        ref="dt"
        class="p-datatable-sm"
        :value="changeLog"
        v-model:selection="selectedIOs"
        dataKey="_id"
        :paginator="true"
        :rows="50"
        v-model:filters="filters"
        filterDisplay="row"
        sortField="created"
        :sortOrder="-1"
        :globalFilterFields="['label', 'description', 'department', 'category']"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 50, 100, 500]"
        currentPageReportTemplate="zeige {first} bis {last} von {totalRecords} LOG-Einträgen"
        responsiveLayout="stack"
        :loading="loading"
        stripedRows
      >
        <template #loading> Daten werden geladen... bitte warten </template>
        <template #header>
          <div class="table-header flex justify-content-between">
            <h5 class="mb-0">Historische Änderungen</h5>
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="suchen..."
              />
            </span>
          </div>
        </template>

        <template #groupheader="slotProps">
          <span class="image-text">{{ formatDateDay(slotProps.data.created) }}</span>
        </template>
        <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
        <Column field="created" header="Zeitstempel" :sortable="true">
          <template #body="slotProps">
            {{ formatDate(slotProps.data.created) }}
          </template>
        </Column>
        <Column field="department" header="Anlage" :sortable="true" filterField="department" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ slotProps.data.department }}</span>
          </template>
          <template v-if="getDepartments !== null" #filter="{filterModel, filterCallback}">
              <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="getDepartments" optionLabel="label" optionValue="key" placeholder="Alle" class="p-column-filter">
                  <template #option="slotProps">
                      <div class="p-multiselect-representative-option">
                          <p>{{ slotProps.option.key }} - <span class="text-xs">{{ slotProps.option.label }}</span></p>
                      </div>
                  </template>
                  <template #value="slotProps">
                      <div class="p-multiselect-representative-option">
                          <p v-if="slotProps.value !== null">{{ getOneDepartment(slotProps.value[0]).key }}  - <span class="text-xs">{{ getOneDepartment(slotProps.value[0]).label }}</span></p>
                          <p v-else>{{ slotProps.placeholder }}</p>
                      </div>
                  </template>
              </MultiSelect>
          </template>
        </Column>
        <Column field="category" header="Kategorie" :sortable="true" filterField="category" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ getOneCategory(slotProps.data.category).label }}</span>
          </template>
          <template v-if="getCategory !== null" #filter="{filterModel, filterCallback}">
              <MultiSelect v-model="filterModel.value" @change="filterCallback()" :options="getCategory" optionLabel="label" optionValue="key" placeholder="Alle" class="p-column-filter">
                  <template #option="slotProps">
                      <div class="p-multiselect-representative-option">
                          <p>{{ slotProps.option.label }}</p>
                      </div>
                  </template>
              </MultiSelect>
          </template>
        </Column>
        <Column field="label" header="Bezeichnung" :sortable="true" filterField="label" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ slotProps.data.label }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column> 
        <Column field="description" header="Beschreibung" :sortable="true" filterField="description" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ slotProps.data.description }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column> 
        <Column field="user" header="Benutzer" :sortable="true" filterField="user" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ slotProps.data.user }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column> 
        <Column field="oldVal" header="Wert vor Änderung" :sortable="true" filterField="oldVal" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ formatValue('oldVal', slotProps.data) }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column> 
        <Column field="newVal" header="Wert nach Änderung" :sortable="true" filterField="newVal" :showFilterMenu="false" >
          <template #body="slotProps">
            <span>{{ formatValue('newVal', slotProps.data) }}</span>
          </template>
          <template #filter="{filterModel, filterCallback}">
            <span class="p-input-icon-left"><i class="pi pi-search" /><InputText v-model="filterModel.value" placeholder="suchen..." @keydown.enter="filterCallback()" /></span>
          </template>
        </Column> 
      </DataTable>
    </div>
  </div>

  <Dialog
    v-model:visible="deleteProductsDialog"
    :style="{ width: '450px' }"
    header="Bestätigung"
    :modal="true"
  >
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
      <span v-if="selectedIOs"
        >Sollen die Einträge wirklich aus der Datenbank gelöscht werden?</span
      >
    </div>
    <template #footer>
      <Button
        label="Nein"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteProductsDialog = false"
      />
      <Button
        label="Ja"
        icon="pi pi-check"
        class="p-button-text"
        @click="deleteSelectedProducts"
      />
    </template>
  </Dialog>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { formatDateLog, formatDateDay, parseNode, mongoResponseToast } from '@/helpers';
import { mapGetters } from "vuex";
import SocketioService from '@/services/socketioService';

export default {
  name: "changeLog",
  data() {
    return {
      loading: false,
      changeLog: [],
      filters: {},
      submitted: false,
      deleteProductsDialog: false,
      selectedIOs: null,
    };
  },
  created() {
    this.initFilters();
    this.$store.dispatch('types/loadCategoryTypes');
    this.$store.dispatch('types/loadDepartmentTypes');
  },
  mounted() {
    this.loading = true;
    this.getParameter();
  },
  beforeUnmount() {
    this.changeLog = [];
    this.filters = {};
    this.submitted = false;
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      getCategory: 'types/getCategory',
      getOneCategory: 'types/getOneCategory',
      getDepartments: 'types/getDepartments',
      getOneDepartment: 'types/getOneDepartment',
      isMaster: 'auth/isMaster',
    }),
  },
  methods: {
    getParameter() {
      this.loading = true;
      SocketioService.getChangeLog((err, response) => {
        if (!err && response !== null) {
          this.loading = false;
          this.changeLog = response;
        }
      });
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      if (this.selectedIOs.length > 0) {
        this.selectedIOs.forEach((entry) => {
          SocketioService.delChangeLog(entry, (err, response) => {
            if (!err && response !== null) {
              const result = mongoResponseToast(response, this.$root);
              if (result) this.getPageData();
              this.loading = false;
            }
          });
        }); 
      }
      this.deleteProductsDialog = false;
      this.selectedIOs = null;
    },
    initFilters() {
      this.filters = {
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'category': { value: null, matchMode: FilterMatchMode.EQUALS },
        'department': { value: null, matchMode: FilterMatchMode.EQUALS },
        'label': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'description': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'oldVal': { value: null, matchMode: FilterMatchMode.EQUALS },
        'newVal': { value: null, matchMode: FilterMatchMode.EQUALS },
        'user': { value: null, matchMode: FilterMatchMode.EQUALS },
      };
    },
    formatDate(timestamp) {
      return formatDateLog(timestamp);
    },
    formatDateDay(timestamp) {
      return formatDateDay(timestamp);
    },
    formatValue(value, node) {
      const newNode = { ...node };
      if (value === 'oldVal') newNode.value = node.oldVal;
      else if (value === 'newVal') newNode.value = node.newVal;
      const scaledNode = parseNode(newNode);
      if (scaledNode.unitStr.includes("#")) {
        scaledNode.unitStr = "";
      }
      return `${scaledNode.value}${scaledNode.unitStr}`;
    }
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-in-enter-active {
  animation: fadeIn linear 0.3s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
@keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}
</style>